<template>
  <div
    class="imagecard group transition-all duration-200 border-image brightness-[.8] hover:brightness-100"
    :class="{
      processing: dataimage.status !== 'unprocessed',
    }"
    @mouseover="playVideo"
    @mouseleave="pauseVideo"
  >
    <!-- Trash Button -->
    <UButton
      color="white"
      icon="i-heroicons-trash-20-solid"
      class="absolute top-4 right-4 z-[9999] opacity-0 group-hover:opacity-100"
    />

    <!-- Regenerate Button -->
    <UButton
      color="gray"
      variant="main"
      label="Regenerate"
      icon="i-heroicons-arrow-path-20-solid"
      class="absolute top-4 right-16 z-[9999] !py-1.5 opacity-0 group-hover:opacity-100"
      @click="regenerate(dataimage.id)"
    />

    <!-- Download Button -->
    <UButton
      color="white"
      icon="i-heroicons-cloud-arrow-down-20-solid"
      class="absolute top-4 left-4 z-[9999] opacity-0 group-hover:opacity-100 z-10"
    />

    <!-- Image and Video Display -->
    <div
      class="relative w-full h-full overflow-hidden h-auto w-full max-h-[1430px] model-image rounded-md object-cover z-1 will-change-[filter]"
    >
      <img
        :src="dataimage.base_image_url"
        alt="Background Image"
        class="w-full h-full object-cover relative"
      />
      <video
        ref="video"
        :src="dataimage.generated_video_url"
        class="absolute top-0 left-0 w-full h-full object-cover z-1"
        loop
        muted
        playsinline
      ></video>
    </div>

    <!-- Garment Info Card -->
    <div
      class="absolute p-2 w-full h-full top-0 left-0 flex items-end gap-2 z-20"
    >
      <UCard
        :ui="{
          background: 'dark:bg-[#1e1f21]',
          ring: 'ring-0',
          base: 'rounded-lg overflow-hidden h-28 w-20',
          body: 'px-0 py-0',
        }"
      >
        <!-- Photo of the product -->
        <div class="h-20">
          <img
            draggable="false"
            :src="dataimage.data.product_image_url"
            alt="Product Photo"
            class="object-contain w-full h-full p-2 dark:bg-[#1e1f21]"
          />
        </div>

        <!-- Product data in the footer -->
        <template #footer>
          <div class="p-0">
            <h3 class="text-[9px] font-semibold">
              {{ dataimage.data.product_name }}
            </h3>
          </div>
        </template>
      </UCard>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

const props = defineProps({
  dataimage: {
    type: Object,
    required: true,
  },
});

const video = ref(null);

const playVideo = () => {
  if (video.value) {
    video.value.play();
  }
};

const pauseVideo = () => {
  if (video.value) {
    video.value.pause();
    video.value.currentTime = 0; // Reset video to the beginning
  }
};

const {
  public: { api_url },
} = useRuntimeConfig();

async function regenerate(imageId) {
  // Logic for regenerating the video
  // Adjust the endpoint and payload as needed
  await fetch(`${api_url}/reel/regenerate`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify({
      imageId,
    }),
  });
}

const loadingGarments = ref([]);
</script>

<style scoped>
/* Ensure the video is hidden initially */
video {
  display: none;
}

/* Show the video on hover */
.imagecard:hover video {
  display: block;
}

/* Additional styles from your original code */
.shimmer-container {
  position: relative;
  display: inline-block;
  overflow: hidden;
}

.model-shimmer {
  mask-mode: luminance;
  position: absolute;
  display: block;
  width: 100%;
  z-index: 20;
  height: 100%;
  top: 0;
  left: 0;
  mask-image: var(--mask-url);
  -webkit-mask-image: var(--mask-url);
  mask-size: cover;
  -webkit-mask-size: cover;
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
}

/* Include any other styles you need */
.imagecard {
  position: relative;
  display: inline-block;
  overflow: hidden;
}

.group:hover .opacity-0 {
  opacity: 1 !important;
}

/* Buttons hover effect */
.imagecard .UButton {
  transition: opacity 0.3s ease;
}
</style>
